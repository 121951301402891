import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import AnimatedText from '../../utils/AnimatedText';
import './Contact.css';
import { addContact } from '../../redux/apiCalls/contactApiCall';
import { useDispatch, useSelector } from 'react-redux';
import MapComponent from '../../components/MapComponent';
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';
import { contactActions } from '../../redux/slices/contactSlice';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function Contact() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sendContactMessage = useSelector((state) => state.contact.sendContactMessage);

    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        country: '', // Ajout de la clé country
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (name, value) => {
        setForm({ ...form, [name]: value });
    };

    const handlePhoneChange = (value, country) => {
        const phoneWithoutCountryCode = value.replace(`+${country.dialCode}`, '').trim();

        setForm({
            ...form,
            phone: phoneWithoutCountryCode, // Mettre à jour le numéro de téléphone sans l'indicatif
            country: `${country.name} +${country.dialCode}` // Mettre à jour le pays
        });
    };

    const validateForm = () => {
        const errors = {};

        if (form.name.length < 2 || form.name.length > 100) {
            errors.name = 'Name must be between 2 and 100 characters long.';
        }

        if (!/\S+@\S+\.\S+/.test(form.email)) {
            errors.email = 'Please enter a valid email address.';
        } else if (form.email.length < 10) {
            errors.email = 'Email address must be at least 10 characters long.';
        }

        if (!form.phone) {
            errors.phone = 'Phone number is required.';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    useEffect(() => {
        if (sendContactMessage) {
            swal({
                title: sendContactMessage,
                icon: sendContactMessage.includes("successfully") ? "success" : "error"
            }).then(isOK => {
                if (isOK && sendContactMessage.includes("successfully")) {
                    navigate("/");
                }
            });
            dispatch(contactActions.setSendContactMessage(null));
        }
    }, [sendContactMessage, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                dispatch(addContact(form));
                setForm({ name: '', email: '', phone: '', country: '', message: '' });
                setErrors({});
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };

    return (
        <>
            <Header title1="Contact Us"
                bgImg="assets/assets/img/headers/contact.jfif"
            />

            <div className="about-section-left contact-bg-image">
                <AnimatedText>
                    <div className="inner-container-right">
                        <h1>Contact Us</h1>

                        <form onSubmit={handleSubmit} className="contact-form">
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={form.name}
                                    onChange={(e) => handleChange('name', e.target.value)}
                                    required
                                    minLength="2"
                                    maxLength="100"
                                />
                                {errors.name && <p className="error">{errors.name}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={form.email}
                                    onChange={(e) => handleChange('email', e.target.value)}
                                    required
                                    minLength="10"
                                />
                                {errors.email && <p className="error">{errors.email}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone</label>
                                <PhoneInput
                                    country={'us'} // Pays par défaut
                                    value={form.phone}
                                    onChange={(value, countryData) => handlePhoneChange(value, countryData)}
                                    required
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                />
                                {errors.phone && <p className="error">{errors.phone}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={form.message}
                                    onChange={(e) => handleChange('message', e.target.value)}
                                ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary btn-xl text-uppercase">Send Message</button>
                        </form>
                    </div>
                </AnimatedText>
            </div>

            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 ps-lg-0 p-4">
                            <div className="position-relative h-100">
                                <MapComponent />
                            </div>
                        </div>
                        <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="section-title text-start">
                                    <h1 className="display-5 mb-4">Contact Us</h1>
                                </div>
                                <div className="lead mx-auto text-muted">
                                    <p>Email: contact@bionicsoul.net</p>
                                    <p>Phone: +216 23 740 723</p>
                                    <p>Address: Sousse</p>
                                </div>
                                <div className="row g-4 mb-4 pb-2">
                                    {/* Additional content here if needed */}
                                </div>
                                <a href="https://www.google.com/maps/place/P%C3%A9pini%C3%A8re+de+Technop%C3%B4le+El+Ghazela,+Rue+IBN+HAMDOUN/@36.8945517,10.1855283,19z/data=!3m1!4b1!4m6!3m5!1s0x12e2cb7173d7cd7f:0x1ec59d1fe9ec5b54!8m2!3d36.8945506!4d10.186172!16s%2Fg%2F11byl57xvw?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D"
                                    className="btn btn-primary py-3 px-5"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Explore More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
